<template>
  <data-list-basic :data="data" :height="height">
    <template slot-scope="{row}">
      <el-row>
        <el-col :span="4"> <i-icon name="icon-car"></i-icon> {{row.VehicleName}} </el-col>
        <el-col :span="2"> <i-icon name="icon-car"></i-icon> {{row.Speed}}km/h </el-col>
        <el-col :span="2"> <i-icon name="icon-car"></i-icon> {{row.Mileage}}km </el-col>
        <el-col :span="2"> <i-icon name="icon-car"></i-icon> {{row.Oil}}L </el-col>
        <el-col :span="14" class="text-align-right"> <i-icon name="icon-car"></i-icon> {{miment(row.RcvTime).format()}} </el-col>
      </el-row>
      <el-row>
        <el-col :span="20" :offset="4"> <i-icon name="icon-car"></i-icon> {{row.StatusDes}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="20" :offset="4"> <i-icon name="icon-car"></i-icon> <span-address :lon="row.Lon" :lat="row.Lat" class="span-address"></span-address> </el-col>
      </el-row>
    </template>
  </data-list-basic>
</template>

<script>
import DataListBasic from './data-list-basic'
import miment from 'miment'
export default {
  props: {
    height: {
      type: String,
      default: 'auto'
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  components: {
    DataListBasic
  },
  data() {
    return {
      miment
    }
  }
}
</script>

<style lang="scss" scoped>
.el-row {
  font-size: 12px;
  margin-bottom: 4px;
  .icon {
    font-size: 14px;
  }
  .span-address {
    margin-left: 2px;
  }
}
.text-align-right {
  text-align: right;
}
</style>
