<template>
  <el-scrollbar wrap-class="data-list" :wrap-style="`height:${height};`">
    <ul v-for="(row, index) in data" :key="index">
      <li>
        <slot :row="row"></slot>
      </li>
    </ul>
  </el-scrollbar>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: 'auto'
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.el-scrollbar ::v-deep{
  .data-list {
    background-color: #F4F4F8;
    padding: 8px;
    ul {
      list-style: none;
      li {
        margin-bottom: 8px;
        background-color: #FFF;
        padding: 8px 12px;
        border-radius: 4px;
      }
    }
  }
}
</style>
